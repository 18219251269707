<template>
	
	<!-- Main Sidebar -->
	<a-layout-sider
		collapsible
		class="sider-primary"
		breakpoint="lg"
		collapsed-width="0"
		width="250px"
		:collapsed="sidebarCollapsed"
		@collapse="$emit('toggleSidebar', ! sidebarCollapsed)"
		:trigger="null"
		:class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
		theme="light"
		:style="{ backgroundColor: 'transparent',}">
			<div class="brand"><img src="images/logos/logo.png" alt=""> <span>WormHole Cloud</span></div>
			<hr>

			<!-- Sidebar Navigation Menu -->
			<a-menu theme="light" mode="inline">
				<a-menu-item>
					<router-link to="/dashboard">
						<span class="icon">
							<img src="/images/icons/act/dashboard.svg" width="20" height="20" v-if="$route.name == 'Dashboard'" />
							<img src="/images/icons/unact/dashboard.svg" width="20" height="20" v-if="$route.name != 'Dashboard'" />
						</span>
						<span class="label">仪表盘</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/nodestatus">
						<span class="icon">
							<img src="/images/icons/act/server.svg" width="20" height="20" v-if="$route.name == 'NodeStatus'" />
							<img src="/images/icons/unact/server.svg" width="20" height="20" v-if="$route.name != 'NodeStatus'" />
						</span>
						<span class="label">节点监控</span>
					</router-link>
				</a-menu-item>
				<a-menu-item>
					<router-link to="/trafficrecoder">
						<span class="icon">
							<img src="/images/icons/act/trafficrecord.svg" width="20" height="20" v-if="$route.name == 'TrafficRecord'" />
							<img src="/images/icons/unact/trafficrecord.svg" width="20" height="20" v-if="$route.name != 'TrafficRecord'" />
						</span>
						<span class="label">流量记录</span>
					</router-link>
				</a-menu-item>
				<!-- <a-menu-item>
					<router-link to="/wiki">
						<span class="icon">
							<img src="/images/icons/act/wiki.svg" width="20" height="20" v-if="$route.name == 'Wiki'" />
							<img src="/images/icons/unact/wiki.svg" width="20" height="20" v-if="$route.name != 'Wiki'" />
						</span>
						<span class="label">使用教程</span>
					</router-link>
				</a-menu-item> -->
				<a-menu-item>
					<router-link to="/ticket">
						<span class="icon">
							<img src="/images/icons/act/gongdan.svg" width="20" height="20" v-if="$route.name == 'Ticket'" />
							<img src="/images/icons/unact/gongdan.svg" width="20" height="20" v-if="$route.name != 'Ticket'" />
						</span>
						<span class="label">工单</span>
					</router-link>
				</a-menu-item>
				<!-- <a-menu-item>
					<router-link to="/my">
						<span class="icon">
							<img src="/images/icons/act/my.svg" width="20" height="20" v-if="$route.name == 'My'" />
							<img src="/images/icons/unact/my.svg" width="20" height="20" v-if="$route.name != 'My'" />
						</span>
						<span class="label">账户信息</span>
					</router-link>
				</a-menu-item> -->
				<a-menu-item class="menu-item-header">
					<a-button @click="LogOut" type="primary" block size="small">
						注销登录
					</a-button>
				</a-menu-item>
			</a-menu>
			<!-- / Sidebar Navigation Menu -->

			<!-- Sidebar Footer -->

			<!-- / Sidebar Footer -->


	</a-layout-sider>
	<!-- / Main Sidebar -->

</template>

<script>

	export default ({
		props: {
			// Sidebar collapsed status.
			sidebarCollapsed: {
				type: Boolean,
				default: false,
			},
			
			// Main sidebar color.
			sidebarColor: {
				type: String,
				default: "primary",
			},
			
			// Main sidebar theme : light, white, dark.
			sidebarTheme: {
				type: String,
				default: "light",
			},
		},
		data() {
			return {
				// sidebarCollapsedModel: this.sidebarCollapsed,
			}
		},
		methods:{
			LogOut(){
				this.$store.commit("setToken",'')
				this.$store.commit("setLoginStatus",false)
				this.$store.commit("setInviteCode","")
				this.$router.push("/sign-in")
			}
		}
	})

</script>
