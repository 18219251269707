import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
Vue.use(VueRouter)

let routes = [
	{
		// will match everything
		path: '*',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/',
		name: 'Index',
		layout: null,
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Index.vue'),
	},
	{
		path: '/dashboard',
		name: 'Dashboard',
		meta:{
			need2Login:true,
			title:'仪表盘',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Dashboard.vue'),
	},
	{
		path: '/nodestatus',
		name: 'NodeStatus',
		meta:{
			need2Login:true,
			title:'节点监控',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/NodeStatus.vue'),
	},
	{
		path: '/trafficrecoder',
		name: 'TrafficRecord',
		meta:{
			need2Login:true,
			title:'流量记录',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/TrafficRecord.vue'),
	},
	{
		path: '/wiki',
		name: 'Wiki',
		meta:{
			need2Login:true,
			title:'使用教程',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/wiki.vue'),
	},
	{
		path: '/ticket',
		name: 'Ticket',
		meta:{
			need2Login:true,
			title:'工单',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/Ticket.vue'),
	},
	{
		path: '/my',
		name: 'My',
		meta:{
			need2Login:true,
			title:'账户信息',
		},
		layout: "dashboard",
		// route level code-splitting
		// this generates a separate chunk (about.[hash].js) for this route
		// which is lazy-loaded when the route is visited.
		component: () => import(/* webpackChunkName: "dashboard" */ '../views/My.vue'),
	},
	{
		path: '/sign-in',
		name: 'Sign-In',
		meta: {
			needLogin:true,
		},
		component: () => import('../views/Sign-In.vue'),
	},
	{
		path: '/sign-up',
		name: 'Sign-Up',
		meta: {
			needLogin:true,
			layoutClass: 'layout-sign-up',
		},
		component: () => import('../views/Sign-Up.vue'),
	},
]

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute( route, parentLayout = "default" )
{
	route.meta = route.meta || {} ;
	route.meta.layout = route.layout || parentLayout ;
	
	if( route.children )
	{
		route.children = route.children.map( ( childRoute ) => addLayoutToRoute( childRoute, route.meta.layout ) ) ;
	}
	return route ;
}

routes = routes.map( ( route ) => addLayoutToRoute( route ) ) ;

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior (to, from, savedPosition) {
		if ( to.hash ) {
			return {
				selector: to.hash,
				behavior: 'smooth',
			}
		}
		return {
			x: 0,
			y: 0,
			behavior: 'smooth',
		}
	}
})

router.beforeEach((to, from, next) => { //全局钩子函数
    to.matched.some((route) => {
        // to.matched.forEach((route) => {
        if (route.meta.need2Login) { //通过此操作可以判断哪些页面需要登录
            if (store.state.userInfo.status) {
                next()
            } else {
                next({ name: 'Sign-In'})
            }
        } else {
			if (route.meta.needLogin) { //通过此操作可以判断哪些页面需要登录
				if (store.state.userInfo.status) {
					next({name:'Dashboard'})
				} else {
					next()
				}
			}else{
				next();
			}
            
        }
    });
 
});
export default router
