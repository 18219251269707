import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userInfo: {
        token:'',
        inviteCode:'',
        status:false,
    }, //用户信息

  },


  getters: {
    getToken(state){
        return state.userInfo.token
    },
    getInviteCode(state){
        return state.userInfo.inviteCode
    },
    getLoginStatus(state){
        return state.userInfo.status
    },
  },


  mutations: {
    setToken(state,value){
        state.userInfo.token = value
    },
    setInviteCode(state,value){
        state.userInfo.inviteCode = value
    },
    setLoginStatus(state,value){
        state.userInfo.status = value
    },
  },


  actions: {

  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })]
})